import React, { useEffect, useRef } from 'react';
import { PanelData, PanelProps } from '@grafana/data';
import { Options } from 'types';
import { init, dispose, registerStyles, registerLocale, CandleTooltipCustomCallbackData, Chart, Nullable } from 'klinecharts'
import {locationService } from '@grafana/runtime';

function redAndGreen(){
  const red = '#F92855'
  const green = '#2DC08E'
  
  const alphaRed = 'rgba(249, 40, 85, .7)'
  const alphaGreen = 'rgba(45, 192, 142, .7)'
  
  registerStyles('green_red', {
    candle: {
      bar: {
        upColor: green,
        downColor: red,
        upBorderColor: green,
        downBorderColor: red,
        upWickColor: green,
        downWickColor: red
      },
      priceMark: {
        last: {
          upColor: green,
          downColor: red
        }
      }
    },
    indicator: {
      ohlc: {
        upColor: alphaGreen,
        downColor: alphaRed
      },
      bars: [{
        style: 'fill',
        borderStyle: 'solid',
        borderSize: 1,
        borderDashedValue: [2, 2],
        upColor: alphaGreen,
        downColor: alphaRed,
        noChangeColor: '#888888'
      }],
      circles: [{
        style: 'fill',
        borderStyle: 'solid',
        borderSize: 1,
        borderDashedValue: [2, 2],
        upColor: alphaGreen,
        downColor: alphaRed,
        noChangeColor: '#888888'
      }]
    }
  })
  
  registerStyles('red_green', {
    candle: {
      bar: {
        upColor: red,
        downColor: green,
        upBorderColor: red,
        downBorderColor: green,
        upWickColor: red,
        downWickColor: green,
      },
      priceMark: {
        last: {
          upColor: red,
          downColor: green,
        }
      }
    },
    indicator: {
      ohlc: {
        upColor: alphaRed,
        downColor: alphaGreen
      },
      bars: [{
        style: 'fill',
        borderStyle: 'solid',
        borderSize: 1,
        borderDashedValue: [2, 2],
        upColor: alphaRed,
        downColor: alphaGreen,
        noChangeColor: '#888888'
      }],
      circles: [{
        style: 'fill',
        borderStyle: 'solid',
        borderSize: 1,
        borderDashedValue: [2, 2],
        upColor: alphaRed,
        downColor: alphaGreen,
        noChangeColor: '#888888'
      }]
    }
  })
}

function transformData(inputData: PanelData, fields: { dt: any; open: any; close: any; high: any; low: any; volume: any; amount: any; uprate: any; change: any; turnover: any; }) {
  const result: { close: any; high: any; low: any; open: any; timestamp: number; volume: any; amount: any; uprate: any; change: any; turnover: any; }[] = [];
  
  inputData.series.forEach((serie: { length: number; fields: any[]; }) => {
    for (let i = 0; i < serie.length; i++) {
      const dataPoint = {
        close: serie.fields.find((field: { name: any; }) => field.name === fields.close).values[i],
        high: serie.fields.find((field: { name: any; }) => field.name === fields.high).values[i],
        low: serie.fields.find((field: { name: any; }) => field.name === fields.low).values[i],
        open: serie.fields.find((field: { name: any; }) => field.name === fields.open).values[i],
        timestamp: new Date(serie.fields.find((field: { name: any; }) => field.name === fields.dt).values[i]).getTime(),
        volume: serie.fields.find((field: { name: any; }) => field.name === fields.volume).values[i],
        amount: serie.fields.find((field: { name: any; }) => field.name === fields.amount).values[i],
        uprate: serie.fields.find((field: { name: any; }) => field.name === fields.uprate).values[i],
        change: serie.fields.find((field: { name: any; }) => field.name === fields.change).values[i],
        turnover: serie.fields.find((field: { name: any; }) => field.name === fields.turnover).values[i],
      };
      result.push(dataPoint);
    }
  });
  
  return result;
}

function changeDashboardTimeRange(currentRange: { from: any; to: any; }, fromDelta: number, toDelta: number, allowFutureTime: any){
  console.log('dashboard当前时间范围:', new Date(currentRange.from), new Date(currentRange.to));

  const change = {
    from:Number(currentRange.from) + Number(fromDelta),
    to:Number(currentRange.to) + Number(toDelta)
  }
  if(!allowFutureTime && change.to > new Date().getTime()){
    change.to = new Date().getTime()
  }
  if(change.to < change.from){//某些时候计算的to<from,这里简单交换一下
    let tmp = change.to;
    change.to = change.from;
    change.from =tmp;
  }

  console.log('dashboard时间范围更新为:', new Date(change.from), new Date(change.to));
  locationService.partial(change,true)
}

//防抖机制
//拖动、缩放图表时,大量触发onScroll onZoom事件,通过防抖机制执行最后一个事件
function debounce(func: { (chart: any, range: any): void; (chart: any, range: any): void; apply?: any; }, wait: number | undefined) {
  let timeout: string | number | NodeJS.Timeout | undefined;

  return function execute(this: any, ...args: any[]) {
      const context = this;
      const later = () => {
          clearTimeout(timeout);
          func.apply(context, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
  };
}

function findTimeUint(data) {
  const limitedData = data.slice(0, 30);//最多计算30个
  
  let minDiff = Infinity;
  for (let i = 0; i < limitedData.length - 1; i++) {
      const currentDiff = limitedData[i + 1].timestamp - limitedData[i].timestamp;
      if (currentDiff < minDiff) {
          minDiff = currentDiff;
      }
  }
  if (data.length < 2) {
      return 0; 
  }
  
  return minDiff;
}

const scrollHandler = debounce(function(chart: { getVisibleRange: () => any; getDataList: () => any; }, range: any) {
  let currentVisableRange = chart.getVisibleRange();

  let delta = null
  let dataList = chart.getDataList();
  let timeUnit = findTimeUint(dataList)
  //let timeUnit = dataList.at(-1).timestamp - dataList.at(-2).timestamp; //时间戳可能不是连续的(节假日),计算的不准
  // if(currentVisableRange.realFrom == 0){
  //   delta = currentVisableRange.realTo - lastVisableRange.realTo
  // }else if(currentVisableRange.realTo == dataList.length){
  //   delta = currentVisableRange.realFrom - lastVisableRange.realFrom
  // }else if(currentVisableRange.from == lastVisableRange.from){
  delta = currentVisableRange.realTo - lastVisableRange.realTo //计算的并不准确,因为有节假日的因素. 
  // }

  console.log("移动:" + delta + ", 单位:" + timeUnit);
  if(delta != null){
    changeDashboardTimeRange(range, delta*timeUnit,delta*timeUnit,currentOptions.other.allowFutureTime);
  }
  lastVisableRange = currentVisableRange;
}, 150);

const zoomHandler = debounce(function(chart: { getVisibleRange: () => any; getDataList: () => any; }, range: any) {
  let currentVisableRange = chart.getVisibleRange();

  let fromDelta = null
  let toDelta = null
  let dataList = chart.getDataList();
  //let timeUnit = dataList.at(-1).timestamp - dataList.at(-2).timestamp;
  let timeUnit = findTimeUint(dataList)

  fromDelta = currentVisableRange.realFrom - lastVisableRange.realFrom
  toDelta = currentVisableRange.realTo - lastVisableRange.realTo
  //console.log("fromDelta:", fromDelta, "toDelta:", toDelta);

  let scale = 1;
  if(dataList.length>100 && dataList.length <=500){
    scale =2;
  }else if(dataList.length > 500){
    scale = 2.5;
  }
  changeDashboardTimeRange(range, fromDelta*timeUnit*scale,toDelta*timeUnit*scale, currentOptions.other.allowFutureTime);
  lastVisableRange = currentVisableRange;
}, 150);

function buildToolTip(data: CandleTooltipCustomCallbackData, options: Options){
  const { prev, current } = data
  const prevClose = (prev?.close ?? current.open)
  //const change = (current.close - prevClose) / prevClose * 100

  //TODO 展示字段做成可配置
  //TODO 涨跌幅使用插件实时计算还是数据库中的字段:可配置
  return [
    { title: 'open', value: current.open.toFixed(2) },
    { title: 'close', value: current.close.toFixed(2) },
    { title: 'high', value:current.high.toFixed(2)},
    { title: 'low', value:current.low.toFixed(2)},
    //自定义的tooltip,前面设置的locale不生效,这里只能手动写
    { title: options.other.lang === 'zh-CN' ? '涨跌额:' : 'Change:', value: {
        text: `${current.change}`,
        color: current.change < 0 
          ? options.candle.color=== 'red_green' ? 'green' : 'red' 
          : options.candle.color=== 'red_green' ? 'red' : 'green'
      }
    },
    { title: options.other.lang === 'zh-CN' ? '涨幅:' : '%Change:', value: {
        text: `${current.uprate}%`,
        color: current.uprate < 0 
          ? options.candle.color=== 'red_green' ? 'green' : 'red' 
          : options.candle.color=== 'red_green' ? 'red' : 'green'
      }
    },
    { title: options.other.lang === 'zh-CN' ? '换手率:' : 'Turnover:', value: `${current.turnover}%`},
    { title: options.other.lang === 'zh-CN' ? '成交量:' : 'Volume:', value: convertNumber(Number(current.volume), options.other.lang)},
    { title: options.other.lang === 'zh-CN' ? '成交额:' : 'Amount:', value: convertNumber(Number(current.amount), options.other.lang)},
  ]
}

function convertNumber(number: number, lang: string) {
  if(lang === 'zh-CN'){
    if (number < 1e4) {
      return number.toString();
    } else if (number < 1e8) {
      return (number / 1e4).toFixed(2) + '万';
    } else {
      return (number / 1e8).toFixed(2) + '亿';
    }  
  }else{
    return number.toLocaleString();
  }
}

let lastVisableRange: { realTo: number; realFrom: number; } | null = null; //当前显示范围
let currentOptions: Options | null = null;
interface Props extends PanelProps<Options> {}
export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const chartContainerRef = useRef(null); // 引用图表容器
  const chartInstanceRef = useRef(null); // 引用图表实例
  currentOptions = options;

  useEffect(() => {
    //console.log("options:", options)

    // 如果已存在图表实例，则清除
    var created = false;
    var chart: Nullable<Chart> = null;    
    if (chartInstanceRef.current) {
        chart = chartInstanceRef.current.setPaneOptions(options);
    }else{//不存在则创建            
      registerLocale('zh-CN', {
        amount: '成交额:',
        uprate: '涨幅:',
        change: '涨跌额:',
        turnover: '换手率:',
        time: '时间:',
        open: '开盘价:',
        high: '最高价:',
        low: '最低价:',
        close: '收盘价:',
        volume: '成交量:'
      })
      registerLocale('en-US', {
        amount: 'Amount:',
        uprate: '%Change:',
        change: 'Change:',
        turnover: 'Turnover:',
        time: 'Time:',
        open: 'Open:',
        high: 'High:',
        low: 'Low:',
        close: 'Close:',
        volume: 'Volume:'
      })

      chart = init(chartContainerRef.current, {
        styles:{
          grid: {
            horizontal: {
              show: options.grid.horizontal.show,
            },
            vertical: {
              show: options.grid.vertical.show,
            }
          },
          candle:{
            type: options.candle.type,
            priceMark:{
              high:{
                show:options.candle.priceMark.high.show
              },
              low:{
                show:options.candle.priceMark.low.show
              },
              last:{
                show:options.candle.priceMark.last.show
              }
            },
            tooltip:{
              showRule:options.candle.candleTooltip.showRule,
              showType:options.candle.candleTooltip.showType,
              custom: (data: CandleTooltipCustomCallbackData) => {
                  return buildToolTip(data, options);
              }
            }
          },
          indicator:{
            tooltip:{
              showRule:options.indicator.indicatorTooltip.showRule,
              showType:options.indicator.indicatorTooltip.showType
            }
          },
          yAxis: {
            show: options.yAxis.show,
            //size: 'auto',
            position: options.yAxis.position,
            type: options.yAxis.type,
            inside: options.yAxis.inside,
            reverse: options.yAxis.reverse,
            // y轴线
            axisLine: {
              show: options.yAxis.show,
              size: 1
            },
            // y轴分割文字
            tickText: {
              show: true,
              family: 'Helvetica Neue',
              weight: 'normal',
              size: 12,
              marginStart: 4,
              marginEnd: 4
            },
            // y轴分割线
            tickLine: {
              show: true,
              size: 1,
              length: 3,
            }
          },
          crosshair:{
            show:true,
            horizontal:{
              show:true,
            },
            vertical:{
              show:true,
            }
          }       
        }
      })
      
      //创建指标
      if('indicator' in options){
        if('mainFigure' in options.indicator){
          for(var i in options.indicator.mainFigure){    
            chart.createIndicator(options.indicator.mainFigure[i],true,{ id: 'candle_pane' });
          }  
        }
        if('subFigure' in options.indicator){
          for(var i in options.indicator.subFigure){      
            chart.createIndicator(options.indicator.subFigure[i]);
          }  
        }  
      }

      //设置涨跌样式
      if('color' in options.candle){
        redAndGreen();
        chart.setStyles(options.candle.color)  
      }

      created = true;
      chartInstanceRef.current = chart
    }

    //更新数据
    const transformed_data = transformData(data,options.candle.fields);
    chart.applyNewData(transformed_data)
    
    //
    chart?.setLocale(options.other.lang);
    chart?.setStyles(options.other.theme);

    let axisColor = options.other.theme === 'light' ? 'rgb(36,41,46)' : 'rgb(204,204,220)' //与grafana中的文本颜色保持一致
    chart?.setStyles({//在init中指定xy轴的颜色不生效,不知道为啥
      xAxis:{
        axisLine:{
          //color: axisColor, 
        },
        tickText: {
          color: axisColor, 
        }
      },
      yAxis: {
        axisLine: {
          //color: axisColor, 
        },
        tickText: {
          color: axisColor, 
        }
      }
    })

    if (options.other.theme === 'dark') {
      chartContainerRef.current.style.backgroundColor = '#1b1b1f'
    }else{//'light'
      chartContainerRef.current.style.backgroundColor = '#ffffff'
    }
  
    //设置展示全部数据
    const visableWidth = chart.getDrawPaneById("candle_pane").getMainWidget().getBounding().width;
    const barSpace = visableWidth / transformed_data.length;
    chart.setBarSpace(Math.max(Math.min(barSpace, 50), 1));
    chart.scrollToTimestamp(transformed_data.at(-1).timestamp);

    if(options.other.autoChangeTime && created && transformed_data.length > 1){
      chart.subscribeAction("onScroll", () => {
        scrollHandler(chart, data.request.range);
      });

      chart.subscribeAction("onZoom", () => {
        zoomHandler(chart, data.request.range);
      });

      //TODO 支持shared crosshair
      // chart?.subscribeAction("onCrosshairChange", ()=>{
      //   this.events.emit('shared-crosshair', { time: hoverTime });
      // })
    }

    lastVisableRange = chart?.getVisibleRange();
    console.log("当前显示范围:", chart.getVisibleRange());
    
    return () => {
      if (chartInstanceRef.current) {
        dispose(chartContainerRef.current);
        chartInstanceRef.current = null;
      }
    }
  }, [options, data, width, height])

  return <div id="chart" ref={chartContainerRef} style={{ width: width, height: height }}/>
}
