import { PanelPlugin } from '@grafana/data';
import { Options } from './types';
import { SimplePanel } from './components/Panel';

const defaultOptions: Options = {
  grid: {
    horizontal: {
      show: false,
    },
    vertical: {
      show: false,
    }
  },
  candle: {
    type: 'candle_solid',
    color:'red_green',
    fields:{
      dt:'dt',
      open:'open',
      close:'close',
      high:'high',
      low:'low',
      volume:'volume',
      amount:'amount',
      uprate:'up_rate',
      change:'change',
      turnover:'exchange_rate',
    },
    priceMark:{
      high:{
        show:false
      },
      low:{
        show:false
      },
      last:{
        show:false
      }
    },
    candleTooltip:{
      showRule:'follow_cross',
      showType: 'standard',
    }
  },
  indicator:{
    indicatorTooltip: {
      showRule: 'follow_cross',
      showType: 'standard',
    },
    mainFigure: [],
    subFigure: []
  },
  yAxis: {
    show: true,
    position: 'left',
    type:'normal',
    inside:false,
    reverse:false,
  },
  crosshair:{
    show: true,
    horizontal:{
      show: true,
    },
    vertical:{
      show: true,
    }
  },
  other:{
    autoChangeTime:true,
    allowFutureTime:false,
    lang:'zh-CN',
    theme:'light',
  }
};

export const plugin = new PanelPlugin<Options>(SimplePanel).setDefaults(defaultOptions).setPanelOptions((builder) => { return builder
  .addNestedOptions({
    category: ['网格线'],
    path: 'grid',
    build: (builder) => {builder
      .addBooleanSwitch({
        path:"horizontal.show",
        name:"水平",
      })
      .addBooleanSwitch({
        path:"vertical.show",
        name:"垂直",
      })
    }
  })
  .addNestedOptions({
    category: ['蜡烛图'],
    path: 'candle',
    build: (builder) => {builder
      .addSelect({
        path:"type",
        name:"图表类型",
        settings:{
          options:[
            { label: '全实心', value: 'candle_solid' },
            { label: '全空心', value: 'candle_stroke' },
            { label: '涨空心', value: 'candle_up_stroke' },
            { label: '跌空心', value: 'candle_down_stroke' },
            { label: 'OHLC', value: 'ohlc' },
            { label: '面积图', value: 'area' }          
          ]
        }
      })
      .addRadio({
        path:"color",
        name:"颜色",
        settings:{
          options:[
            { label: '红涨绿跌', value: 'red_green' },
            { label: '绿涨红跌', value: 'green_red' },
          ]
        }
      })
      .addBooleanSwitch({
        path:"priceMark.high.show",
        name:"显示最高价",
      })
      .addBooleanSwitch({
        path:"priceMark.low.show",
        name:"显示最低价",
      })
      .addBooleanSwitch({
        path:"priceMark.last.show",
        name:"显示最新价",
      })
      .addRadio({
        path:"candleTooltip.showRule",
        name:"提示条",
        settings:{
          options:[
            {
              value:'always',
              label:'始终显示',
            },
            {
              value:'follow_cross',
              label:'跟随光标',
            },
            {
              value:'none',
              label:'不显示',
            },
          ]
        }
      })
      .addRadio({
        path:"candleTooltip.showType",
        name:"提示条样式",
        settings:{
          options:[
            {
              value:'standard',
              label:'标准',
            },
            {
              value:'rect',
              label:'矩形框',
            },
          ]
        }
      })
    }
  })
  .addNestedOptions({
    category: ['字段'],
    path: 'candle',
    build: (builder) => {builder
      .addTextInput({
        path:"fields.dt",
        name:"时间",
      })
      .addTextInput({
        path:"fields.open",
        name:"开盘价",
      })
      .addTextInput({
        path:"fields.close",
        name:"收盘价",
      })
      .addTextInput({
        path:"fields.high",
        name:"最高价",
      })
      .addTextInput({
        path:"fields.low",
        name:"最低价",
      })
      .addTextInput({
        path:"fields.volume",
        name:"成交量",
      })
      .addTextInput({
        path:"fields.amount",
        name:"成交额",
      })
      .addTextInput({
        path:"fields.uprate",
        name:"涨幅",
      })
      .addTextInput({
        path:"fields.change",
        name:"涨跌额",
      })
      .addTextInput({
        path:"fields.turnover",
        name:"换手率",
      })
    }
  })
  .addNestedOptions({
    category: ['指标'],
    path: 'indicator',
    build: (builder) => {builder
      .addMultiSelect({
        path:"mainFigure",
        name:"主图指标",
        settings:{
          options:[
            {label:'MA(移动平均线)', value:'MA'},
            {label:'EMA(指数平滑移动平均线)', value:'EMA'},
            {label:'SMA', value:'SMA'},
            {label:'BOLL(布林线)', value:'BOLL'},
            {label:'SAR(停损点指向指标)', value:'SAR'},
            {label:'BBI(多空指数)', value:'BBI'}
          ]
        }
      })
      .addMultiSelect({
        path:"subFigure",
        name:"附图指标",
        settings:{
          options:[
            {label:'MA(移动平均线)', value:'MA'},
            {label:'EMA(指数平滑移动平均线)', value:'EMA'},
            {label:'VOL(成交量)', value:'VOL'},
            {label:'MACD', value:'MACD'},
            {label:'BOLL(布林线)', value:'BOLL'},
            {label:'KDJ(随机指标)', value:'KDJ'},
            {label:'RSI(相对强弱指标)', value:'RSI'},
            {label:'BIAS(乖离率)', value:'BIAS'},
            {label:'BRAR(情绪指标)', value:'BRAR'},
            {label:'CCI(顺势指标)', value:'CCI'},
            {label:'DMI(动向指标)', value:'DMI'},
            {label:'CR(能量指标)', value:'CR'},
            {label:'PSY(心理线)', value:'PSY'},
            {label:'DMA(平行线差指标)', value:'DMA'},
            {label:'TRIX(三重指数平滑平均线)', value:'TRIX'},
            {label:'OBV(能量潮指标)', value:'OBV'},
            {label:'VR(成交量变异率)', value:'VR'},
            {label:'WR(威廉指标)', value:'WR'},
            {label:'MTM(动量指标)', value:'MTM'},
            {label:'EMV(简易波动指标)', value:'EMV'},
            {label:'SAR(停损点指向指标)', value:'SAR'},
            {label:'SMA', value:'SMA'},
            {label:'ROC(变动率指标)', value:'ROC'},
            {label:'PVT(量价趋势指标)', value:'PVT'},
            {label:'BBI(多空指数)', value:'BBI'},
            {label:'AO(动量震动指标)', value:'AO'}
          ]
        }
      })
      .addRadio({
        path:"indicatorTooltip.showRule",
        name:"提示",
        settings:{
          options:[
            {
              value:'always',
              label:'始终显示',
            },
            {
              value:'follow_cross',
              label:'跟随光标',
            },
            {
              value:'none',
              label:'不显示',
            },
          ]
        }
      })
      .addRadio({
        path:"indicatorTooltip.showType",
        name:"提示条样式",
        settings:{
          options:[
            {
              value:'standard',
              label:'标准',
            },
            {
              value:'rect',
              label:'矩形框',
            },
          ]
        }
      })
    }
  })
  .addNestedOptions({
    category: ['Y轴'],
    path: 'yAxis',
    build: (builder) => { builder
      .addBooleanSwitch({
        path:"show",
        name:"显示",
      })
      .addRadio({
        path:"position",
        name:"位置",
        settings:{
          options:[
            {
              value:'left',
              label:'左',
            },
            {
              value:'right',
              label:'右',
            },
          ]
        }
      })
      .addBooleanSwitch({
        path:"inside",
        name:"内部",
      })
      .addBooleanSwitch({
        path:"reverse",
        name:"倒置",
      })
      .addRadio({
        path:"type",
        name:"坐标轴类型",
        settings:{
          options:[
            {
              value:'normal',
              label:'普通',
            },
            {
              value:'percentage',
              label:'百分比',
            },
            {
              value:'log',
              label:'对数',
            },
          ]
        }
      })
    },
  })
  .addNestedOptions({
    category: ['其他'],
    path: 'other',
    build: (builder) => {builder
      .addBooleanSwitch({
        path:"autoChangeTime",
        name:"图表时间范围变化时联动修改面板时间",
      })
      .addBooleanSwitch({
        path:"allowFutureTime",
        name:"拖动时允许面板展示未来时间",
      })
      .addRadio({
        path:"lang",
        name:"语言",
        settings:{
          options:[
            {
              value:'zh-CN',
              label:'中文',
            },
            {
              value:'en-US',
              label:'英文',
            },
          ]
        }
      })
      .addRadio({
        path:"theme",
        name:"主题",
        settings:{
          options:[
            {
              value:'light',
              label:'浅色',
            },
            {
              value:'dark',
              label:'深色',
            },
          ]
        }
      })
      ;
    }
  });
});
